import * as React from 'react';
import { Line } from 'react-chartjs-2'

function SpendingActivityLine({chartData}) {
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: ' Alfasente Crunched Datalytics',
          },
        },
      };
    return ( 
        <div>
            <Line data={chartData} options={options} />
        </div>
     );
}

export default SpendingActivityLine;