import "./SendMoney.css"
import React,{useRef, useState} from 'react'
import {Navigate} from "react-router-dom"
import {Container,Row,Col} from 'react-bootstrap'
import { read, utils } from "xlsx"
import tutor from "./tutor.png"
import xlpng from "./excel.png"


const excel = require('../../assets/xlsx.ico')
const axios = require("axios").default;


const SendMoney = () => {
  let mybtn = useRef(null);
  let myCTA = useRef(null);
  let [spinnerOn,setSpinnerOn] = useState(false);
  const [jsonObj, setjsonObj] = useState();
  const [fileName, setFileName] = useState();
  
  

  const triggerFileUpload = () => {
    mybtn.current.click()
  }

  const handleUpload = async () => {
     const xlfile = mybtn.current.files[0];
    setFileName(xlfile.name);
    myCTA.current.hidden = false;
    // put data in array buffer
    const data = await xlfile.arrayBuffer();
    const workbook = read(data)

    workbook.SheetNames.forEach((sheetName)=>{
      var XL_row_object = utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
      setjsonObj(XL_row_object);
      // console.log(json_object);
    })
  }

  const sendmoney = async () =>{
    var payload = [];
    jsonObj.forEach((item)=>{
      payload.push({
        "amount": ""+item["Amount"],
        "currency": "EUR",
        "externalId": "123456789",
        "payee": {
            "partyIdType": "MSISDN",
            "partyId": ""+ item["Number"]
        },
        "payerMessage": "Hey",
        "payeeNote": "Receive Money",
    });
    });
    setSpinnerOn(true);
    // console.log(JSON.stringify(payload));
    var config = {
      method: 'post',
      url: 'https://alfasentebulkpayment.azurewebsites.net/api/bulk_payments',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : JSON.stringify(payload)
    };
    
    axios(config)
    .then(function (response) {
      window.alert("Payment made")
      window.location.replace("/transactions")
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const titleStyle = {
    fontFamily:"Poppins"
  }

  const sendMoneyMainContainer = {
    display:"flex"
  }

  const tutorContainer = {
    width:"30em"
  }

  return (
    <Container>
      <p className='mt-4 ' style={titleStyle}>Send Money</p>
      <hr id = 'line'/>
      <div style={sendMoneyMainContainer}>
        <div style={tutorContainer}>
          <p>Upload an excel file with such a format to make a bulk payment</p>
          <img alt="tutorial" src={tutor} style={{
            width:"450px",
            objectFit:"contain",
          }}/>
        </div>
      <div style={{ width: '18rem'}}>
       <p style={{
         backgroundColor:"rgb(212, 10, 185)",
         padding:"1em",
         borderRadius:"20px",
         width:"10em",
         textAlign:"center",
         color:"white",
         marginLeft:"6em",
         cursor:"pointer",
         marginBottom:"2em"
       }} onClick={triggerFileUpload}>Choose a File</p>
         <input type="file" hidden ref={mybtn} onChange={handleUpload} />
         <div hidden ref={myCTA}>
         <img style={{ display:"inline", width:"30px",objectFit:"contain", marginLeft:"8em"}} src={xlpng}/> <span>{fileName}</span>
         <div style={{marginTop:"3em", display:"flex"}}>
           <input type="password" placeholder="PIN TO CONFIRM" style={{
             marginLeft:"5.5em",
             backgroundColor:"rgb(232, 234, 236)",
             borderRadius:"20px",
             padding:"0.8em",
             border:"0px",
             display:"inline"
           }} />
           <i onClick={sendmoney} style={{fontSize:"2.3em", marginLeft:"0.5em", color:"rgb(212, 10, 185)",cursor:"pointer"}} class="bi bi-arrow-right-circle-fill"></i>
         </div>
         {/* <SpinnerCircular style={{marginLeft:"4em", marginTop:"2em"}} enabled={spinnerOn} color="rgb(212, 10, 185)"/> */}
         </div>
      </div>
      </div>
      {/* <Row>
        <Col>
          <button className="btn primary" type="button">Cancel</button>
        </Col>
        <Col className="d-md-flex justify-content-md-end">
          <button className="btn primary me-md-2" type="button">Next</button>
        </Col>
      </Row> */}


    </Container>
  )
}

export default SendMoney