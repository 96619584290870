import React from 'react'
import {Container} from 'react-bootstrap'
import {FaLessThan}from 'react-icons/fa'
import './Success.css'

const Success = ()=>{
    return (
        <Container>
            <div className="d-grid gap-2 mt-5">
            <button className="btn secondary" type="button">Conguratulations!!! You have successfully made a bulk transfer</button>
            </div>
        
            <div className="mt-5  d-md-flex justify-content-md-end">
                <button className="btn primary me-md-2" type="button"><FaLessThan/><FaLessThan/>Dashboard</button>
                
            </div>
        </Container>
    )
    
    

}

export default Success