import React from 'react'
import {useLocation } from "react-router-dom";
import { Container,Navbar,Nav,NavDropdown} from 'react-bootstrap'
import { IoSettingsSharp  } from "react-icons/io5";
import {BsPersonCircle} from 'react-icons/bs';
import {Link} from 'react-router-dom'
import { AiFillDollarCircle, AiOutlineWallet } from "react-icons/ai"
import waving from "./waving.svg"


const NavBar = () => {
    const location = useLocation();

    const imageStyle = {
        display:"inline",
        width:"30px",
    }
 
  return (
    <Navbar bg="light" expand="lg" className='ms-3' >
        <Navbar.Brand >
            <img src={waving} style={imageStyle}/> <span style={{color:"Orange"}}>Hi Joanita !</span> <br/>   
            Your Balance is <span style={{color:"green"}} >UGX.200,000</span> 
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
             
        <Navbar.Collapse id="basic-navbar-nav"className="justify-content-center">
            <Nav >
                <Nav.Link  className='ms-5'> <AiFillDollarCircle color={'Orange'} size={25} /><br/> <Link to = '/send'className='text-decoration-none'  style={{color: "black"}}> Send Money  </Link></Nav.Link>
                <Nav.Link className='ms-5'> <AiOutlineWallet  color={'purple'} size={25} /><br/> <Link to = '/top_up'className='text-decoration-none '  style={{color: "black"}}> TopUp Wallet  </Link></Nav.Link>
                                         
            
            </Nav>
        </Navbar.Collapse>                    
        <Navbar.Collapse  id="basic-navbar-nav" className="justify-content-end pe-5 me-5">
            <Nav >
                <Navbar.Text className='pe-5'> 
                    <NavDropdown title={<BsPersonCircle size={35}/>} id="basic-nav-dropdown">
                        
                        <NavDropdown.Item  className='text-decoration-none' > NAKITYO JOANITA</NavDropdown.Item>
                        <NavDropdown.Item  className='text-decoration-none' > nakityoanita@gmail.com</NavDropdown.Item>
                        <NavDropdown.Item > <IoSettingsSharp color={'green'} size={25}/> Settings</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item >Logout</NavDropdown.Item>
                    </NavDropdown> 
                </Navbar.Text>
            </Nav>
        </Navbar.Collapse>
</Navbar>
  )
}

export default NavBar