import React from 'react';
import { Cards } from '../Analytics/Cards';
import SpendingActivityLine from '../SpendingActivity/SpendingActivityLine';
import faker from '@faker-js/faker';

function AnalyticsLayout() {
    const chartContainerStyle = {
        // display:"flex",
        width:"60%",
        marginRight:"auto",
        marginLeft:"auto",
        // justifyContent:"center",
        marginTop:"4em",
        // height:"300px"
    }

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const data = {
      labels,
      datasets: [
        {
          label: 'Spending Activity',
          data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
          backgroundColor: '#7F7FD5',
          borderColor: 'rgb(255, 99, 132)',
        },
        {
          label: 'Spending Activity',
          data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
          backgroundColor: '#E786D7',
          borderColor: 'rgb(53, 162, 235)',
        },
      ],
    }; 
    return ( 
        <div>
            <Cards/>
            <div style={chartContainerStyle}>
                <SpendingActivityLine chartData={data}/>
            </div>
        </div>
     );
}

export default AnalyticsLayout;