import React from 'react';
import NavBar from '../NavBar/NavBar';
import SpendingActivity from '../SpendingActivity/SpendingActivity';
import TransactionList from '../TransactionList/TransactionList';
import { faker } from '@faker-js/faker';

function Overview() {

    const chartContainerStyle = {
        display:"flex",
        justifyContent:"center",
        // marginTop:"1em",
        height:"300px"
    }

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const data = {
      labels,
      datasets: [
        {
          label: 'Spending Activity',
          data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
          backgroundColor: '#7F7FD5',
          borderColor: 'rgb(255, 99, 132)',
        },
        {
          label: 'Spending Activity',
          data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
          backgroundColor: '#E786D7',
          borderColor: 'rgb(53, 162, 235)',
        },
      ],
    }; 
    return ( 
        <div>
            <NavBar/>
          <div style={chartContainerStyle}>
          <SpendingActivity chartData={data}/>
          </div>
          <h3 style={{paddingLeft:"1em"}}>Latest Transactions</h3>
          <div className='tableContainer'>
            <TransactionList/>
          </div>
        </div>
     );
}

export default Overview;