import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Routes,Route} from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import TransactionList from './components/TransactionList/TransactionList'
import Display from './components/SpendingActivity/Display';
import Top from './components/TopuP/Top';
import Main from './components/Main/Main';

import NavBar from './components/NavBar/NavBar'
import { Settings } from './components/Settings/Settings';
import Withdraw from './components/Withdraw/Withdraw';
import SendMoney from './components/SendMoney/SendMoney';
import Next from './components/SendMoney/Next';
import Success from './components/SendMoney/Success';
import Dashboard from './components/Dashboard/Dashboard';
import Overview from './components/Overview/Overview';
import AnalyticsLayout from './components/AnalyticsLayout/AnalyticsLayut';
import TransactionsLayout from './components/TransactionsLayout/TransactionsLayout';
import LandingPage from './components/LandingPage/LandingPage';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path = "/sideBar" element={<Sidebar/>} />
          <Route path="/table" element={<TransactionList/>}/>
          <Route path="/chart" element={<Display/>} />
          <Route path="/top_up" element={<Dashboard><Top/></Dashboard>} />
          <Route path="/main" element={<Main/>} />
          <Route path = "/navBar" element={<NavBar/>} />
          <Route path = "/transactions" element={<Dashboard><TransactionsLayout/></Dashboard>} />
          <Route path = "/settings" element={<Dashboard><Settings/></Dashboard>} />
          <Route path = "/withdraw" element={<Dashboard><Withdraw /></Dashboard>} />
          <Route path = "/send" element={<Dashboard><SendMoney/></Dashboard>} />
          <Route path = "/next" element={<Next/>} />
          <Route path = "/success" element={<Success/>} />
          <Route path='/home' element={<Dashboard><Overview/></Dashboard>}/>
          <Route path='/analytics' element={<Dashboard><AnalyticsLayout/></Dashboard>}/>
          <Route path='/' element={<LandingPage/>}/>
        </Routes>
      </Router>

    </div>
  );
}

export default App;
