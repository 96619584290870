import React from 'react';
import './Sidebar.css';
import { NavLink} from "react-router-dom";
import {Col, Row} from 'react-bootstrap'; 
import { BsFillHouseDoorFill, BsFillArrowRightCircleFill} from "react-icons/bs";
import { AiFillDollarCircle, AiOutlineWallet } from "react-icons/ai"
import { BiTransferAlt } from "react-icons/bi";
import { MdOutlineAnalytics } from "react-icons/md";
import { IoSettingsSharp } from "react-icons/io5";
import logo from "./logo.png"

const Sidebar = () => {

    const logoStyle = {
        fontFamily:"'Montserrat', sans-serif",
        color:"rgb(61, 57, 57)",
        fontWeight:"20px"
    }

  return (
    
        <div className='h-100 sideBar' style={{height:"100%"}}>
            
            <div>
                {/* <NavLink to="/" style={{textDecoration:"None"}}><h1 style={logoStyle} className='ps-4 pt-3'>Alfasente</h1></NavLink> */}
                <NavLink to="/"><img src={logo} width="200" style={{paddingTop:"0.5em", paddingLeft:"1em", paddingRight:"1em"}}/></NavLink>
                <h4 className='ps-4 pt-2 pb-2 menu-link'>Menu</h4>
                
                <Row  className='ps-1 pt-2'>
                    <Col>
                        <NavLink
                            to ="/home" 
                            className ="nav-link"
                            activeClassName="active"
                        > 
                            <BsFillHouseDoorFill color={'blue'} size={25} />  Overview
                        </NavLink>
                    </Col>
                
                </Row> 
                <hr className='mt-3'/>
                <Row  className='ps-1 pt-1 '>
                    <Col>
                        <NavLink
                            to ="/analytics"
                            className ="nav-link"
                            activeClassName="active"
                        >
                            <MdOutlineAnalytics color={'purple'} size={25}/> Analytics
                        </NavLink>
                    </Col>
                   
                  
                </Row>
                <hr className='mt-3' />
                <Row className='ps-1 pt-1'>
                    <Col>
                        <NavLink
                        to ="/transactions"
                        className ="nav-link"
                        activeClassName="active"
                        >
                            <BiTransferAlt color={'blue'} size={25}/> Transactions  
                            
                        </NavLink>
                    </Col>
                 
                </Row>
                <hr className='mt-3' />
                <Row className='ps-1 pt-1'>
                    <Col>
                        <a
                        href="/send"
                        className ="nav-link"
                        activeClassName="active"
                        >
                            <AiFillDollarCircle color={'Orange'} size={25}/> Send Money
                        </a>
                    </Col>
                  
                </Row>
                 
                <hr className='mt-3' />
                <Row className='ps-1 '>
                    <Col>
                        <NavLink
                        to ="/withdraw"
                        className ="nav-link"
                        activeClassName="active"
                        >
                            <BsFillArrowRightCircleFill color={'green'} size={25}/> Withdraw 
                        </NavLink>
                    </Col>
                    
                </Row>
                <hr className='mt-3' />
                <Row  className='ps-1 '>
                    <Col>
                        <NavLink
                        to ="/top_up"
                        className ="nav-link"
                        activeClassName="active"
                        >
                            <AiOutlineWallet  color={'purple'} size={25} /> Topup wallet
                        </NavLink>
                    </Col>
                
                </Row>
                <hr className='mt-3'/>
                <Row className='ps-1 pb-4 '>
                    <Col>
                        <NavLink
                        to ="/settings"
                        className ="nav-link"
                        activeClassName="active"
                        >
                            <IoSettingsSharp  color={'green'} size={25}/>  Settings  
                        </NavLink>
                    </Col>
                    
                    
                </Row>
            
               
            </div>
           
            
        </div>
   
   
  )
}

export default Sidebar