import "./LandingPage.css"
import React from 'react';
import logo from "./logo.png"


function LandingPage() {
    return ( 
        <div>

{/* <!-- Nav Area --> */}
<nav className="navbar navbar-expand-sm navbar-dark bg-light">
    <div className="container-fluid">
        <a className="navbar-brand" href="/"><img width="150"
                src={logo} alt="LOGO"/></a>
        <button className="navbar-toggler text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
            <i className="bi bi-list"></i>
        </button>
        <div className="collapse navbar-collapse ml-4" id="mynavbar">
            <ul className="navbar-nav me-auto mx-auto">
                <li className="nav-item">
                    <a className="nav-link text-dark" href="">Home</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link text-dark" href="#aboutUs">About Us</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link text-dark" href="#contact-us-section">Contact Us</a>
                </li>
            </ul>
            <a href="/home"><button className="home__SignInCTA"><span className="minor-text">Dashboard</span> </button></a>
        </div>
    </div>
</nav>
    
    
{/* <!-- Hero Area --> */}
<div className="home-heroSection">
    <div className="heroContent">
        <p className="headingOnHome">EASING DISSEMINATION OF FUNDS</p>
        <div className="heroBtnContainer">
            
            <a href="/home"><button className="home__SignInCTA"><span className="btnHero">Dashboard</span> </button></a>
            
        </div>
        <p className="headingOnHomeMin">Own a Business? Distribute funds with us now</p>
    </div>
</div>
<div className="donateTodaySectionContainer">
    <p className="donateHead">Why Choose Us?</p>
    <div className="cardContainer">
        <div className="row" id="row">
            <div className="col-sm">
                <div className="card" id="card" style={{width:"300px"}}>
                    <img id="card-img-top" className="card-img-top"
                        src={"https://cdn.jsdelivr.net/gh/Hack-Girls/Nigharimie_assets@master/953.jpg"} alt="Card image"
                        style={{width:"100%",height:"200"}}/>
                    <div className="card-body">
                        <p className="card-title text-center NGO-Name">SECURE</p>
                        <p className="card-text minor-text text-center">We provide secure enterpise grade payment solutions for Businesses that do humanitarian work</p>
                        {/* <!-- <p className="card-text"><span className="minor-text">Total Donations</span> <span
                                className="moneyColor">USD100000</span></p>
                        <div className="donateHomebtn">
                            <button className="home__DonateCTA"><span className="minor-text">Donate</span> </button>
                        </div> --> */}
                    </div>
                </div>
            </div>
            <div className="col-sm">
                <div className="card" id="card" style={{width:"300px"}}>
                    <img id="card-img-top" className="card-img-top"
                        src={"https://cdn.jsdelivr.net/gh/Hack-Girls/Nigharimie_assets@master/5392786.jpg"}
                        alt="Card image" style={{width:"100%"}}/>
                    <div className="card-body">
                        <p className="card-title text-center NGO-Name">RELIABLE</p>
                        <p className="card-text minor-text text-center">Our services are reliable. With servers having 99.9% uptime, you are gauranteed service delivery all the time</p>
                        {/* <!-- <p className="card-text"><span className="minor-text">Total Donations</span> <span
                                className="moneyColor">USD100000</span></p>
                        <div className="donateHomebtn">
                            <button className="home__DonateCTA"><span className="minor-text">Donate</span> </button>
                        </div> --> */}
                    </div>
                </div>
            </div>
            <div className="col-sm">
                <div className="card" id="card" style={{width:"300px"}}>
                    <img id="card-img-top" className="card-img-top"
                        src={"https://cdn.jsdelivr.net/gh/Hack-Girls/Nigharimie_assets@master/emmanuel-ikwuegbu-Oy64kOi3cK0-unsplash.jpg"}
                        alt="Card image" style={{width:"100%"}}/>
                    <div className="card-body">
                        <p className="card-title text-center NGO-Name">CONVINIENT</p>
                        <p className="card-text minor-text text-center">All you have to do is upload an Excel sheet of the people to whom you want to send money and let us to the job.</p>
                        {/* <!-- <p className="card-text"><span className="minor-text">Total Donations</span> <spandecide
                                className="moneyColor">USD100000</span></p>
                        <div className="donateHomebtn">
                            <button className="home__DonateCTA"><span className="minor-text">Donate</span> </button>
                        </div> --> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    <div id="aboutUs" className="homeAboutUsSection container-fluid">
        <p className="aboutUsHead">About Us</p>
        <div className="row aboutUsRow">
            <div className="col-sm">
                <h5 className="homeQn">Who are we?</h5>
                <div className="icon-cont">
                    <i className="bi bi-heart-fill"></i>
                </div>
                <p className="aboutUsP minor-text">We are company that payment solutions
                    to Businesses as they pay their service
                    providers</p>
            </div>
            <div className="col-sm">
                <h5 className="homeQn">What we do?</h5>
                <div className="icon-cont">
                    <i className="bi bi-award"></i>
                </div>
                <p className="aboutUsP minor-text">We are providing Business payment
                    solutions which help to disseminate
                    funds to their beneficiaries and service
                    providers </p>
            </div>
            <div className="col-sm">
                <h5 className="homeQn">Why?</h5>
                <div className="icon-cont">
                    <i className="bi bi-patch-check-fill"></i>
                </div>
                <p className="aboutUsP minor-text">To ease dissemination o funds and
                    providing accountability of expenditures </p>
            </div>
        </div>
    </div>
    <div className="receive-funds-section">
        <p className="receive-fundsP">Are you running a Business that needs to make payments in bulk. We can help you with that.  
        </p>
        <div className="receiveFundsBTN">
            
            <a href="/home"> <button className="home__DonateCTA"><span className="minor-text">Dashboard</span>
                </button></a>
            
        </div>
    </div>
    <div className="container">
        <div className="row">
            <div className="col-sm">
                <img src={"https://cdn.jsdelivr.net/gh/Hack-Girls/Nigharimie_assets@master/Contact%20us-rafiki.svg"}
                    alt="contact-us" width="500"/>
            </div>
            <div className="col-sm">
                <p id="contact-us-section" className="donateHead">CONTACT US</p>
                <div className="contact-form-container">
                    <form action="/contact-us/" method="POST">
                        <div>
                            <input className="contactInput" type="text" placeholder="Subject"/>
                        </div>
                        <div>
                            <input className="contactInput" type="text" placeholder="Message"/>
                        </div>
                        <div>
                            <input className="contactInput" type="text" placeholder="Email"/>
                        </div>
                        <div className="contactFormBTNContainer">
                            <button className="contactSubmit"><span style={{color:"white"}}
                                    className="minor-text">Submit</span></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div className="footer-dark">
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-3 item text">
                        <img src={logo}
                            alt="white-logo" width="150"/>
                        <p className="minor-text text-dark">Money for good</p>
                    </div>
                    <div className="col-md-3 item">
                        {/* <!-- <h3>Services</h3>
                        <ul>
                            <li><a href="#">Web design</a></li>
                            <li><a href="#">Development</a></li>
                            <li><a href="#">Hosting</a></li>
                        </ul> --> */}
                    </div>
                    <div className="col-md-3 item">
                        {/* <!-- <h3>About</h3>
                        <ul>
                            <li><a href="#">Company</a></li>
                            <li><a href="#">Team</a></li>
                            <li><a href="#">Careers</a></li>
                        </ul> --> */}
                    </div>
                    <div className="col-md-3 item">
                        <h3 className="text-dark">Services</h3>
                        <ul>
                            <li><a href="#" className="minor-text text-dark">Funds Dissemination</a></li>
                        </ul>
                    </div>
                    <div className="col item social"><a href="#"><i className="bi bi-facebook"></i></a><a href="#"><i
                                className="bi bi-twitter"></i></a><a href="#"><i className="bi bi-youtube"></i></a><a href="#"><i
                                className="bi bi-instagram"></i></a><a href="#"><i className="bi bi-google"></i></a>
                    </div>
                </div>
                <p className="copyright minor-text text-dark">NIGHARIMIE &copy; 2021</p>
            </div>
        </footer>
    </div>
        </div>
     );
}

export default LandingPage;