import "./Dashboard.css"
import React from 'react';
import Sidebar from '../Sidebar/Sidebar';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
  } from 'chart.js';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
  );


function Dashboard({children}) {

    const mainContainerStyle = {
        display:"grid",
        gridTemplateColumns:"auto auto"
    }

    const sideBarContainerStyle = {
        gridColumnStart:1,
        gridColumnEnd:2,
    }

    return ( 
        <div style={mainContainerStyle}>
            <div style={sideBarContainerStyle}>
                <Sidebar/>
            </div>
            <div>{children}</div>
        </div>
     );
}

export default Dashboard;