import React from 'react';
import Transactions from '../Transactions/Transactions';
import TransactionList from '../TransactionList/TransactionList';

function TransactionsLayout() {
    return ( 
        <div style={{paddingRight:"20em"}}>
            <Transactions/>
            <TransactionList/>
        </div>
     );
}

export default TransactionsLayout;