import React from 'react'
import {Form, Button,Row, Col, Container} from 'react-bootstrap'
import './settings.css'

export const Settings = () => {
  return (
      <Container>
          <Row className='ps-5 mt-4 ms-4'>
            <Col>
            <p>Personal Information</p>
            </Col>
            < Col className='me-5 justify-content-md-end d-grid gap-2 '  >
                <Button variant="outline-success csv" className='me-md-2'>save</Button>          
                            
            </Col>
           
        </Row>
        
        <Form className='m-5 ps-5'>
             <hr id='line' />
            <Row className="mb-3 mt-5">
                <Form.Group as={Col} controlId="formGridName">
                <Form.Label>First Name</Form.Label>
                <Form.Control  placeholder="Enter First Name" />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="password" placeholder="Enter Last Name" />
                </Form.Group>
            </Row>

            <Form.Group className="mb-3 mt-5" controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter email" />
                <Form.Text className="text-muted">
                    We'll use this email if we need to contact you about your account
                </Form.Text>
            </Form.Group>


            <Row className="mb-3 mt-5">
                <Form.Group as={Col} controlId="formGridNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control placeholder="Phone Number"/>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPassword" className='pt-4 mt-2'>
                    <Button>change Password</Button>  
                </Form.Group>    
                
            </Row>

            <Button variant="primary" type="submit">
                Submit
            </Button>
        </Form>
      </Container>
    
  )
}
