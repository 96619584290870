import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

const Next = () => {
  return (
    <Container>
        <p className='mt-4 '>Bulk Transfer</p>
        <hr id = 'line'/>
        <p>Click on the send transfer button to send the money</p>
        <Row className='pt-5'>
            <Col>
                <p>Review Transfers</p>
            </Col>
            <Col>
                <div className="d-md-flex justify-content-md-end">
                    <button className="btn primary me-md-2 active" type="button">Send Transfer</button>
                </div>
            </Col>
            
        </Row>

        <div class="mt-5  d-md-flex justify-content-md-end">
            <button class="btn primary me-md-2" type="button">Next</button>
            
        </div>
        
    </Container>
    
  )
}

export default Next