import { Bar } from "react-chartjs-2";


function SpendingActivityChart({ chartData }) {

    const chartContainerStyle = {
      width:"60%"
    }
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Your Spending Activity over the passed months',
          },
        },
      };

    return ( 
        <div style={chartContainerStyle}>
            <Bar
            data={chartData}
            options={options}
            />
        </div>
     );
}

export default SpendingActivityChart;