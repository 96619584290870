import React from 'react'
import { Container, Card, Form } from 'react-bootstrap'
import './withdraw.css'

const Withdraw = () => {
  return (
      <div style={{paddingRight:"30em"}}>
        <Container>
            <p className='mt-4 '>Request Withdraw</p>
            <hr id = 'line'/>
            <Card style={{ width: '18rem'}}>
                    <Card.Body>
                        <Card.Title style={{ color:'green'}}>Balance</Card.Title>
                        <Card.Text >
                            Ugshs.200,000               
                        </Card.Text>
                        
                    </Card.Body>
            </Card>
            <Form.Group className="mb-3 mt-5" controlId="formGridNumber">
                <Form.Text className="text-muted">
                    Enter the amount you would want to withdraw
                </Form.Text>
            
                <Form.Control type="number" placeholder="Please enter the amount" />
            
            </Form.Group>
            <Form.Group className="mb-3 mt-5" controlId="formGridNumber">
                <Form.Text className="text-muted">
                    Enter the number you would want to withdraw to <br/>
                </Form.Text>
                <Form.Label className="mt-3">MOBILE NUMBER</Form.Label>
                <Form.Control type="number" placeholder="Please enter mobile number" />
            
            </Form.Group>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button className="btn primary me-md-2" type="button">Cancel</button>
                <button className="btn primary" type="button">Confirm</button>
            </div>
        </Container>
      </div>
  )
}

export default Withdraw