import React from 'react';
import SpendingActivityChart from '../SpendingActivity/SpendingActivity';
import SpendingActivityLine from '../SpendingActivity/SpendingActivityLine'
import TransactionList from '../TransactionList/TransactionList';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
  } from 'chart.js';
  import { faker } from '@faker-js/faker';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
  );
  

function Main() {
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const data = {
      labels,
      datasets: [
        {
          label: 'Spending Activity',
          data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          borderColor: 'rgb(255, 99, 132)',
        },
        {
          label: 'Spending Activity',
          data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          borderColor: 'rgb(53, 162, 235)',
        },
      ],
    }; 
    const mainContainerStyle = {
        display : "grid",
        gridTemplateColumns:"auto auto auto auto",
    }

    const leftContainerStyle = {
        gridColumnStart:1,
        gridColumnEnd:1,
        // backgroundColor:"red"
        width:"400px"
    }

    return ( 
        <div style={mainContainerStyle}>
            <div style={leftContainerStyle} >
                <TransactionList/>
                <SpendingActivityLine chartData={data}/>
            </div>
            <div className="table">
                <SpendingActivityChart chartData={data}/>
            </div>
        </div>
     );
}

export default Main;