import React from 'react';
import Table from 'react-bootstrap/Table'

function TransactionList() {
    return ( 
        <div className="p-4">
            <Table hover bordered >
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Reason</th>
                        <th>Amount</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Mayengo Ivan</td>
                        <td>Salary</td>
                        <td>20000</td>
                        <td>21/01/23</td>
                    </tr>
                    <tr>
                        <td>John Bruce</td>
                        <td>Supplies</td>
                        <td>23892</td>
                        <td>12/12/21</td>
                    </tr>
                    <tr>
                    <td>John Bruce</td>
                        <td>Supplies</td>
                        <td>23892</td>
                        <td>12/12/21</td>
                    </tr>
                    <tr>
                    <td>Mayengo Ivan</td>
                        <td>Salary</td>
                        <td>20000</td>
                        <td>21/01/23</td>
                    </tr>
                    <tr>
                    <td>Mayengo Ivan</td>
                        <td>Salary</td>
                        <td>20000</td>
                        <td>21/01/23</td>
                    </tr>
                    <tr>
                    <td>John Bruce</td>
                        <td>Supplies</td>
                        <td>23892</td>
                        <td>12/12/21</td>
                    </tr>
                    <tr>
                        <td>Okello Elijah</td>
                        <td>Food</td>
                        <td>340000</td>
                        <td>13/12/22</td>
                    </tr>
                    <tr>
                    <td>John Bruce</td>
                        <td>Supplies</td>
                        <td>23892</td>
                        <td>12/12/21</td>
                    </tr>
                    <tr>
                    <td>John Bruce</td>
                        <td>Supplies</td>
                        <td>23892</td>
                        <td>12/12/21</td>
                    </tr>
                    <tr>
                    <td>Okello Elijah</td>
                        <td>Food</td>
                        <td>340000</td>
                        <td>13/12/22</td>
                    </tr>
                    <tr>
                    <td>Okello Elijah</td>
                        <td>Food</td>
                        <td>340000</td>
                        <td>13/12/22</td>
                    </tr>
                </tbody>
            </Table>
        </div>
     );
}

export default TransactionList;