import React from 'react'
import { Container, Form, FormControl, Button, Col, Row } from 'react-bootstrap'
import {BsSearch, BsDownload} from 'react-icons/bs'
import './Transactions.css'

const Transactions = () => {
  return (
    <Container>
        <Row>
            <Col>
            <Form className="d-flex mt-5" style={{ width: '18rem'}}>
                <FormControl
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
                
                />
                <Button variant="outline-success"><BsSearch/></Button>
            </Form>
            </Col>
            <Col className="mt-5 justify-content-end " >
                <Button variant="outline-success csv">Download CSV <BsDownload/></Button>          
                            
            </Col>
        </Row>

    </Container>
  )
}

export default Transactions