import React from 'react'
import {Row, Col,Card} from 'react-bootstrap'

export const Cards = () => {
  return (
    <Row>
        <Col className='ms-5 mt-5'>
            <Card style={{ width: '18rem', backgroundColor:'green', color:'white'}}>
                <Card.Body>
                    <Card.Title>Balance</Card.Title>
                    <Card.Text>
                        Ugshs.200,000               
                    </Card.Text>
                    
                </Card.Body>
            </Card>
        </Col>
        <Col className='mt-5'>
            <Card style={{ width: '18rem', backgroundColor:'orange', color:'white' }}>
                <Card.Body>
                    <Card.Title>Deposits</Card.Title>
                    <Card.Text>
                        271 transactions               
                    </Card.Text>
                    
                </Card.Body>
            </Card>
        </Col>
        <Col className='mt-5'>
            <Card style={{ width: '18rem', backgroundColor:'purple', color:'white'}}>
                <Card.Body>
                    <Card.Title>Withdraws</Card.Title>
                    <Card.Text>
                        271 transactions               
                    </Card.Text>
                    
                </Card.Body>
            </Card>
        </Col>
    </Row>
  )
}
