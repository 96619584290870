import { FormControl,Form, FormGroup, FormLabel, Button} from 'react-bootstrap';
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import flag from "./uganda.png"

function Top() {
    const changeNumberType = () => {
        console.log("I have been pressed")
    }
    // setup the step content
    const step1Content = (
        <div id="main-content" style={{paddingTop:"4em",paddingLeft:"8em",paddingRight:"8em"}}>
            <h1>Send Top-up</h1>
            <p className='small text-secondary'>Enter the wallet/phone number that you would like to recharge</p>
            <Form.Check type='radio' label="Mobile Number" onClick={changeNumberType} />
            <Form>
            <Form.Group className="mb-3">
                    <Form.Label style={{paddingRight:"1em"}}><img style={{marginBottom:"5px"}} width={50} src={flag}/></Form.Label>
                    <Form.Control style={{width:"400px",display:"inline"}} type="email" placeholder="+256 XXXXXXXXX" />
                </Form.Group>
            </Form>
            <Form.Check type='radio' label="Wallet Number"/>
            <Form>
            <Form.Group className="mb-3">
                    <Form.Label style={{paddingRight:"1em"}}><img style={{marginBottom:"5px"}} width={50} src={flag}/></Form.Label>
                    <Form.Control style={{width:"400px",display:"inline"}} type="email" placeholder="+256 XXXXXXXXX" />
                </Form.Group>
            </Form>
        </div>
    );
    const step2Content = "";
    const step3Content = "";
    const step4Content = "";
    
    // setup step validators, will be called before proceeding to the next step
    function step2Validator() {
    return true
    }
    
    function step3Validator() {
    return true
    }
    
    function onFormSubmit() {
    // handle the submit logic here
    // This function will be executed at the last step
    // when the submit button (next button in the previous steps) is pressed
    }
    return ( 
        <div>
            {/*  render the progress bar */}
            <StepProgressBar
            startingStep={0}
            onSubmit={onFormSubmit}
            steps={[
                {
                label: 'Number',
                subtitle: '10%',
                name: 'step 1',
                content: step1Content
                },
                {
                label: 'Order',
                subtitle: '25%',
                name: 'step 2',
                content: step2Content,
                validator: step2Validator
                },
                {
                label: 'Summary',
                subtitle: '75%',
                name: 'step 3',
                content: step3Content,
                validator: step3Validator
                },
                {
                label: 'Payment',
                subtitle: '100%',
                name: 'step 3',
                content: step4Content,
                validator: step3Validator
                }
            ]}
            />
        </div>
     );
}

export default Top;